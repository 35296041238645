





















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import ConfirmationInput from '@/components/ConfirmationInput.vue'
import AccountService from '@/services/economy/AccountService'
import Account from '@/models/economy/Account'
import VatCodeService from '@/services/economy/VatCodeService'

@Component({
  components: {
    ConfirmationInput,
  },
  directives: {},
})
export default class Edit extends Vue {
  private isSaving = false
  private vatCodes = []
  private rules = {}
  private data: Account = null
  private original: Account = null
  private confirmation = false
  private error = ''
  private types = [
    { id: 'sales', name: 'Sales' },
    { id: 'rounding', name: 'Rounding' },
    { id: 'payment', name: 'Payment' },
    { id: 'vat', name: 'VAT' },
    { id: 'cost', name: 'Cost' },
    { id: 'stock', name: 'Stock' },
  ]

  public created(): void {
    this.data = new Account()
    this.original = new Account()
    this.rules = {
      number: [
        (v) => !!v || this.$t('c:validation:This field is required'),
        (v) => (!isNaN(parseInt(v)) && parseInt(v) !== 0) || this.$t('c:validation:Must be an integer'),
      ],
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
      type: [(v) => !!v || this.$t('c:validation:This field is required')],
      vatId: [(v) => !!v || this.data?.type !== 'sales' || this.$t('c:validation:This field is required')],
    }
    this.load()
  }

  private get id(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  private load() {
    VatCodeService.loadAll()
      .then((vatCodes) => {
        this.vatCodes = vatCodes
        AccountService.loadOne(this.id).then((account) => {
          this.data = account
          this.original = account.clone()
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    this.error = ''
    if (this.hasWarnings && !this.confirmation) {
      this.error = 'You must confirm that you understand the warning(s)'
      return
    }
    if (this.isNew) {
      AccountService.create(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save account')
        })
    } else {
      this.data.id = this.id
      AccountService.update(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save account')
        })
    }
  }

  private clickCancel() {
    this.$router.back()
  }

  private get hasWarnings() {
    return this.warnings.length > 0
  }

  private get warnings() {
    const result = []
    let n = 0
    if (!this.isNew && this.numberChangedWarning) {
      n++
      result.push({ id: n, text: this.numberChangedWarning })
    }
    if (this.stockChangeWarning) {
      n++
      result.push({ id: n, text: this.stockChangeWarning })
    }
    if (this.roundingChangeWarning) {
      n++
      result.push({ id: n, text: this.roundingChangeWarning })
    }
    if (!this.isNew && !this.roundingChangeWarning && !this.stockChangeWarning && this.typeChangeWarning) {
      n++
      result.push({ id: n, text: this.typeChangeWarning })
    }
    return result
  }

  private get numberChangedWarning() {
    if (this.isNew) {
      return null
    }
    const a = this.data?.number ? '' + this.data.number : ''
    const b = this.original?.number ? '' + this.original.number : ''
    return a === b
      ? null
      : 'If you change account number, all accounting rules that point to this account will also be changed to the new account number'
  }

  private get roundingChangeWarning() {
    const a = this.data?.type ? '' + this.data.type : ''
    const b = this.original?.type ? '' + this.original.type : ''
    if (a !== b) {
      if (a === 'rounding') {
        return 'There can only be one rounding account - if any other accounts of type rounding should exist, they will be deleted'
      }
      if (b === 'rounding') {
        return 'You will no longer have a rounding account - be sure to add a new one afterwards'
      }
    }
    return ''
  }

  private get stockChangeWarning() {
    const a = this.data?.type ? '' + this.data.type : ''
    const b = this.original?.type ? '' + this.original.type : ''
    if (a !== b) {
      if (a === 'stock') {
        return 'There can only be one stock account - if any other accounts of type stock should exist, they will be deleted'
      }
      if (b === 'stock') {
        return 'You will no longer have a stock account - be sure to add a new one afterwards'
      }
    }
    return ''
  }

  private get typeChangeWarning() {
    const a = this.data?.type ? '' + this.data.type : ''
    const b = this.original?.type ? '' + this.original.type : ''
    return a === b ? null : 'Changing the account type may not be a good idea if the account is already in use'
  }
}
