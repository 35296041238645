export default class VatCode {
  id: number
  name: string
  vatCode: string
  externalVatCode: string
  percent: number
  accountId: number
  vatCategory: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.name = data.name ? (data.name as string) : ''
    this.vatCode = data.vatCode ? (data.vatCode as string) : ''
    this.vatCategory = data.vatCategory ? (data.vatCategory as string) : ''
    this.externalVatCode = data.externalVatCode ? (data.externalVatCode as string) : ''
    this.percent = data.percent ? (data.percent as number) : null
    this.accountId = data.accountId ? (data.accountId as number) : null
  }

  public clone(): VatCode {
    const result = new VatCode()
    Object.assign(result, this)
    return result
  }
}
