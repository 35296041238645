import { axios } from '@/plugins/vueaxios'
import VatCode from '@/models/economy/VatCode'

export default class VatCodeService {
  public static loadOne(id: number): Promise<VatCode> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/vat-codes/' + id)
        .then((response) => {
          resolve(new VatCode(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static loadAll(): Promise<VatCode[]> {
    return new Promise((resolve, reject) => {
      return axios
        .get('/v4/site/economy/vat-codes?perPage=500')
        .then((response) => {
          const result = []
          response.data.data.forEach((v) => {
            result.push(new VatCode(v))
          })
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static create(data: VatCode): Promise<VatCode> {
    return new Promise((resolve, reject) => {
      axios
        .post('/v4/site/economy/vat-codes', data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public static update(data: VatCode): Promise<VatCode> {
    return new Promise((resolve, reject) => {
      axios
        .put('/v4/site/economy/vat-codes/' + data.id, data)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
