











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class ConfirmationInput extends Vue {
  @Prop({ type: Boolean, default: false }) public value: boolean
  @Prop({ type: String, default: 'Enter the email address of the user you are logged in as to confirm that you understand' })
  public label: string

  private text = ''
  private valid: boolean = null

  private get _label() {
    return this.$t(this.label)
  }

  private get _hint() {
    if (this.valid === null) {
      return ''
    }
    return this.$t(this.valid ? 'OK' : 'Incorrect email address')
  }

  private onBlur() {
    const expectEmail = vxm.user?.profile?.email || ''
    if (!this.text) {
      this.valid = null
    } else {
      if (this.text && this.text.toLowerCase().trim() === expectEmail.toLowerCase().trim()) {
        this.valid = true
      } else {
        this.valid = false
      }
    }
    this.$emit('input', !!this.valid)
  }
}
